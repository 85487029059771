.SegmentsContainer {
  width: 100%;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  font-family: 'Montserrat', sans-serif;
  color: #f4f4f4;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  align-content: center;
  padding: 0rem 2rem;
  .ContainerSegment {
    border-bottom: 2px solid #6a25f2;
    display: grid;
    grid-template-columns: 2fr 1fr auto;
    align-items: center;
    gap: 1rem;
    .title {
      font-size: 1.2rem;
      margin-left: 35px;
    }
    button {
      padding: 0;
      svg {
        font-size: 2.5rem;
        color: white;
      }
    }
  }
  .containerSubSegment {
    display: grid;
    align-items: center;
    .header-info {
      display: grid;
      grid-template-columns: auto 1fr 1fr 0.2fr;
      align-items: center;
      border-bottom: 2px solid #6a25f2;
      .expand {
        max-height: 500px; /* Ajusta según el contenido */
        transition: opacity 2s ease-in-out;
        animation: slideDown 0.3s ease-out forwards;
      }

      .icons {
        margin-left: 30px;
        display: flex;
        gap: 0.5rem;
        align-items: center;
        justify-content: center;
      }
    }

    .expanded-content {
      max-height: 500px;
      overflow: hidden;
      transition: opacity 2s ease-in-out;
      animation: slideDown 0.3s ease-out forwards;
      opacity: 0;
      .subtitle-header {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        align-items: center;
        justify-content: left;
        color: #6b26f6;
        margin-left: 10px;
        .header-icons {
          display: grid;
          grid-template-columns: repeat(7, 1fr);
          align-items: center;
          border-radius: 8px;
        }
        .add-button {
          justify-self: end;
        }
      }
      .name-result-header {
        grid-template-columns: repeat(7, 1fr);
        display: grid;
        align-items: center;
        justify-content: left;
        margin-left: 10px;
        .icon-button {
          display: flex;
          color: white;
          align-items: center;
        }
      }
    }
  }
}

.deleteConceptContainer {
  display: flex;
  gap: 1rem;
  .title-segment {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 15%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left-component {
      position: absolute;
      left: 0;
    }
    .center-component {
      font-family: 'Montserrat', sans-serif;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .FileAreaSegment {
    width: 100%;
    display: flex; /* Opcional, si el contenido interno necesita centrado */
    justify-content: center;
    align-items: center;

    .file-upload-preview {
      max-width: 320px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px; /* Ajusta la altura según necesidad */
      margin: auto; /* Centrado en caso de ser necesario */
    }
  }
  .SelectValue {
    display: flex;
    width: 100%;
    justify-content: center;
  }
}
.title-segment::after {
  content: '';
  position: absolute;
  top: 52px;
  right: 0;
  width: 100%; /* Grosor del borde */
  height: 3px;
  background: linear-gradient(90deg, #e516bb 0%, #6125f0 100%);
  animation: 2s linear infinite; /* Aplica animación */
}
.deleteConceptContainerLocation {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  .title-location {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 15%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left-component {
      position: absolute;
      left: 0;
    }
    .center-component {
      font-family: 'Montserrat', sans-serif;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .input-large {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      height: 100%;
      justify-content: center;
      text-align: center;
      margin-bottom: 10px;
      padding-left: 10px;
    }
    .ant-input {
      height: 100%;
      margin-bottom: 10px;
      align-items: center;
      justify-content: center;
      display: flex;
      text-align: center;
    }
  }
  .custom-number-input {
    width: 100%;
    font-weight: normal;
    height: 50px;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    label {
      height: 80%;
      width: 150px;
      display: flex;
      align-items: center;
      margin-left: 10px;
      margin-bottom: 20px;
    }
    input {
      appearance: none;
      -moz-appearance: 'textfield';
      -webkit-appearance: none;
      height: 80%;
      width: 75px;
      background: transparent;
      border: none;
      outline: none;
      margin-bottom: 10px;
      align-items: center;
      color: white;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 30px;
      margin-bottom: 10px;
      font-size: 14px;
      font-weight: normal;
    }

    .controls {
      position: absolute;
      top: 0;
      right: 0;
      gap: 0px;
      margin-top: 5px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .controls button {
      background: none;
      border: none;
      font-size: 12px;
      cursor: pointer;
      color: #ffffff;
      line-height: 1;
      width: 30px;
      height: 50%;
    }

    .controls button:hover {
      color: #6f18a0;
    }
  }
  .button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }
}

.title-location::after {
  content: '';
  position: absolute;
  top: 52px;
  right: 0;
  width: 100%; /* Grosor del borde */
  height: 3px;
  background: linear-gradient(90deg, #e516bb 0%, #6125f0 100%);
  animation: 2s linear infinite; /* Aplica animación */
}
