.newClientForm {
  h2 {
    color: white;
    margin-bottom: 10px;
  }
}
.title {
  color: white;
  margin-bottom: 10px;
}
