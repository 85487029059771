.TitlePageContainer {
  margin: 4% 2%;
  display: flex;
  align-items: center;
  gap: 1rem;
  h1 {
    font-size: 2em;
    font-weight: lighter;
  }
  svg {
    font-size: 1.5rem;
    color: #8850f9;
  }
}
