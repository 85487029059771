.container-worker {
  height: 100%;
  width: 100%;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.2);
  font-family: 'Montserrat', sans-serif;
  color: #f4f4f4;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  align-content: center;
  padding: 0rem 1rem;
  .title {
    margin-top: 30px;
    height: 10%;
    font-weight: 500px;
    margin-bottom: 0px;
    h2 {
      font-size: 40px;
    }
  }
  .search-section {
    height: 10%;
    .search-bar-container {
      width: 40%;
    }
    .circular-button {
      width: 50px;
      height: 50px;
      .plus-icon {
        font-size: 3rem;
      }
    }
  }
}

.newClientForm {
  .title-staff {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 15%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left-component {
      position: absolute;
      left: 0;
    }
    .center-component {
      font-family: 'Montserrat', sans-serif;
      position: absolute;
      left: 50%;
      width: 300px;
      transform: translateX(-50%);
      display: flex;
      justify-content: center;
      align-items: center;
      .creating {
        font-size: 25px;
      }
      .updating {
        font-size: 20px;
      }
    }
  }
  .container-inputs {
    margin-top: -50px;
  }
}

.title-staff::after {
  content: '';
  position: absolute;
  top: 65px;
  right: 0;
  width: 100%; /* Grosor del borde */
  height: 3px;
  background: linear-gradient(90deg, #e516bb 0%, #6125f0 100%);
  animation: 2s linear infinite; /* Aplica animación */
}
.actionsContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
