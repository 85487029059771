.popup-overlay-confirm {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  .popup {
    padding: 20px 20px;
    border-radius: 5px;
    text-align: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-evenly;
    height: 50%;
    gap: 12px;
    .regexi {
      color: #05a660;
      font-size: 25px;
      font-weight: bold;
    }

    p {
      color: white;
      margin-bottom: 20px;
      justify-content: center;
    }

    .button-container {
      display: flex;
      justify-content: space-between;

      .accept-button,
      .reject-button {
        // background: linear-gradient(to right, #f600c0 0%, #4409a4 100%);
        background-color: rgb(38, 26, 58);
        color: white;
        height: 45px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: relative;
        outline: none;
        font-size: 16px;
        border-radius: 12px;
        padding: 2px;
        border: none;
      }

      .accept-button:hover,
      .reject-button:hover {
        filter: brightness(1.2); /* Aumenta el brillo */
        transform: scale(1); /* Ligeramente más grande */
        background-color: rgb(124, 16, 132);
      }

      .accept-button:active,
      .reject-button:active {
        transform: scale(0.98); /* Efecto de clic */
      }
      .accept-button[disabled],
      .reject-button[disabled] {
        opacity: 0.6;
        color: #888;
        cursor: not-allowed;
      }

      .reject-button {
        background: #444;
      }
    }
  }
}
