.paymentsDetail-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  flex-direction: row;
  .containerphoto {
    margin: 0 1rem;
    width: 35%;
    height: 50%;
    .receipt {
      border-top: 3px solid #5a1fd1;
      border-bottom: 3px solid #5a1fd1;
      width: 90%;
      height: 90%;
      justify-content: center;
      align-items: center;
      text-align: center;
      .receipt-item {
        display: flex;
        flex-direction: column;
        margin: 20px 5px;
        p {
          display: flex;
          width: 100%;
        }
      }
    }
    .photo {
      width: 35%;
      height: 85%;
      position: absolute;
      top: 7%;
      img {
        object-fit: cover;
        border-radius: 10%;
      }
    }
  }

  .inf {
    height: 95%;
    width: 65%;
    padding: 0 1rem;

    .database-container {
      display: flex;
      align-content: center;
      align-items: center;
      justify-content: space-between;
      border-color: #5a1fd1;
      border-bottom-style: inset;

      .data {
        text-align-last: left;
        p {
          font-size: 1.2rem;
          width: 100%;
          line-height: 1.7rem;
        }
        p:last-child {
          font-size: 1rem;
          padding-top: 7px;
        }
      }
      .status {
        width: 25%;
        margin-top: auto;

        svg {
          font-size: 4rem;
          animation: none;
        }
      }

      .svgPdf {
        margin-top: auto;
      }
    }

    .statement {
      display: flex;
      align-items: center;
      align-content: center;
      flex-wrap: nowrap;
      flex-direction: column;
      margin: 1.2rem 0;
      gap: 0.5rem;
      max-height: 60%;
      min-height: 130px;
      overflow: overlay;

      .detail {
        display: grid;
        width: 100%;
        align-items: center;
        justify-items: center;
        text-align: center;
        align-content: center;
        position: relative;
        margin-top: 8px;
      }
    }
    .totalStatement {
      display: flex;
      justify-content: flex-end;
      align-content: center;
      flex-wrap: nowrap;
      align-items: baseline;
      gap: 7rem;
      border-color: #5a1fd1;
      border-top-style: outset;
      padding-top: 1rem;
      span {
        margin-right: 3.5rem;
      }
    }

    .actions {
      display: flex;
      justify-content: space-around;
      align-items: center;
      align-content: center;
      flex-direction: row;
      flex-wrap: nowrap;
      bottom: 4rem;
      margin-top: 5rem;
      .custom-button {
        padding: 10px 7px;
      }
    }
  }
}

.InfoModal {
  width: 65%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin: 1rem;
  .header {
    width: 100%;
    p {
      margin: 0 auto;
      width: auto;
    }
    .Amounts {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;

      p {
        margin: 0;
        width: auto;
      }
      .custom-number-input {
        position: relative;
        width: 100%;

        input {
          appearance: none;
          -moz-appearance: 'textfield';
          -webkit-appearance: none;
          width: 70%;
          background-color: transparent;
          border: none;
          outline: none;
          text-align: end;
          color: white;
        }

        .controls {
          position: absolute;
          top: 0;
          right: 0;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }

        .controls button {
          background: none;
          border: none;
          font-size: 10px;
          cursor: pointer;
          color: #ffffff;
          line-height: 1;
          width: 30px;
          height: 50%;
        }

        .controls button:hover {
          color: #c567fc;
        }
      }
    }
  }
  .buttom {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    .inner {
      cursor: pointer;
      height: 40px;
      width: 130px;
      text-align: center;
      align-items: center;
      font-size: 16px;
      font-weight: bold;
    }
  }
}

.modalReject {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin: 0 1rem;
  .myTexarea {
    width: 95%;
    p {
      margin: 0 !important;
    }
  }
  p {
    width: 100%;
    margin: 0;
  }
  .buttom {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    .inner {
      cursor: pointer;
      height: 40px;
      width: 130px;
      text-align: center;
      align-items: center;
      font-size: 16px;
      font-weight: bold;
    }
  }
}

.receipt-expanded {
  width: 320px;
  height: 300px;
  display: flex;
  flex-direction: column;
  border-top: 3px solid #5a1fd1;
  border-bottom: 3px solid #5a1fd1;
  padding: 10px 0 5px 0;
  margin-left: 20px;
  position: relative;
  .receipt-title {
    font-size: 1em; /* Aumenta el tamaño de la fuente */
    color: #faebd7; /* Color del texto, usa uno que combine con tu paleta de colores */
    margin-bottom: 25px; /* Espacio debajo del título */
    text-transform: uppercase; /* Convierte el texto a mayúsculas */
    letter-spacing: 1px; /* Espaciado entre las letras */
    font-family: 'Montserrat', sans-serif; /* Define la fuente */
    border-bottom: 1px solid #5a1fd1; /* Añade un borde inferior */
  }
  .receipt-table {
    display: flex;
    flex-direction: column; /* Asegura que los <p> se acomoden en columna */
    align-items: flex-start; /* Asegura que los <p> estén alineados a la izquierda */
    gap: 8px; /* Espacio entre los párrafos */
    margin-top: 5px;
    p {
      text-align: left; /* Asegura que el texto se alinee a la izquierda */
      margin-left: 10px; /* Elimina cualquier margen por defecto */
      width: 80%;

      .small-text {
        font-size: 1em; /* Reduce el tamaño del texto */
      }
    }
  }
}

// @media screen and (max-width: 1024px) {
//   .receipt-expanded {
//     width: 280px; /* Reducimos el ancho */
//     height: 270px; /* Ajustamos la altura */
//     padding: 8px 0 4px 0;
//     margin-left: 15px;
//   }

//   .receipt-expanded .receipt-title {
//     font-size: 0.9em; /* Reducimos ligeramente el tamaño del título */
//     margin-bottom: 20px;
//   }

//   .receipt-expanded .receipt-table p {
//     width: 90%; /* Aumentamos el ancho del texto */
//     font-size: 0.9em; /* Reducimos el tamaño del texto */
//     margin-left: 8px;
//   }
//   .receipt-expanded img {
//     width: 50px;
//     margin-left: 200px;
//     margin-bottom: 500px;
//     margin-top: -60px;
//   }
// }

// @media screen and (max-width: 768px) {
//   .receipt-expanded {
//     width: 250px; /* Más pequeño en pantallas móviles */
//     height: 250px;
//     margin-left: 10px;
//   }

//   .receipt-expanded .receipt-title {
//     font-size: 0.85em;
//     margin-bottom: 15px;
//   }

//   .receipt-expanded .receipt-table p {
//     width: 95%;
//     font-size: 0.85em;
//     margin-left: 5px;
//   }
//   .receipt-expanded .receipt-image {
//     width: 40px; /* Hacemos la imagen más pequeña */
//     display: block;
//     // margin: 10px auto; /* Centrar en móviles */
//     margin-left: 1000px;
//     background-color: red;
//     border-radius: 5px; /* Redondear esquinas */
//     padding: 5px;
//   }
// }
