.reportPage {
  padding-top: 2rem;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  color: #f4f4f4;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  .containerTitle {
    display: flex;
    align-items: center;
    .title {
      font-size: 44px;
      font-weight: bold;
    }
  }
  .containerFilters {
    display: flex;
    width: 100%;
    .containerInputFilters {
      display: flex;
      flex-direction: row;
      width: 90%;
      margin-left: 20px;
      .container-top {
        width: 100%;
        .inputDark {
          width: 80%;
          height: 60px;
          display: flex;
          max-width: 400px;
          min-width: 250px;
          margin-bottom: -5px;
          .date-range-picker-container {
            width: 97%;
          }
        }
      }
      .container-bottom {
        width: 100%;
        .container-autocomplete {
          margin-bottom: 20px;
          height: 45px;
          max-width: 400px;
          min-width: 250px;
          .MuiOutlinedInput-root {
            width: 45%;
            margin-bottom: 12px;
          }
        }
        .container-select {
          margin-bottom: 20px;
          .select-container {
            margin-bottom: 20px;
            height: 45px;
            max-width: 500px;
            min-width: 400px;
          }
        }
      }
    }
    .containerButtonsFilters {
      margin: 10px;
      width: 200px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  .svgStatus {
    width: '100%';
    display: 'flex';
    justify-content: 'center';
    align-items: 'center';
    font-size: '30px';
    gap: '0.5rem';
    svg {
      font-size: 1.5rem;
    }
  }
}
.ContainerPreView {
  width: 100%;
  height: 100%;
  .header {
    display: flex;
    border-bottom: 2px solid #5a1fd1;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    button {
      margin: 0 20px;
    }
  }
  .dataContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    padding: 1rem 1rem;
    .data1 {
      width: 50%;
      p {
        width: 100%;
        text-align: left;
      }
    }

    .data2 {
      width: 50%;
      p {
        width: auto;
        text-align: right;
        margin: 0;
      }
      .datasvg {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        svg {
          font-size: 2rem;
          margin-right: 1rem;
        }
      }
    }
  }
  .photos {
    width: 100%;
    display: flex;
    height: 120px;
    align-items: stretch;
    align-content: flex-start;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 1rem;
    justify-content: space-around;
    .ImgBorder {
      width: 30%;
    }
    img {
      margin: 0;
    }
  }
  .status {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 2rem;

    .checkIn {
      width: 35%;
      p {
        font-weight: 700;
        width: auto;
        text-align: right;
        margin: 0;
      }
    }

    .checkSVG {
      width: 15%;
      margin: 0 1rem;
      svg {
        font-size: 90px;
      }
    }

    .checkOut {
      width: 35%;
      p {
        font-weight: 700;
        width: auto;
        text-align: left;
        margin: 0;
      }
    }
  }
}
