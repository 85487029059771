.containerCheckInDetail {
  padding: 0em 1em;
  height: 325px;
  .images-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    .single-image {
      align-items: center;
      background-position: 50%;
      background-repeat: no-repeat;
      background-size: contain;
      display: flex;
      height: 105px;
      justify-content: center;
      width: calc(25% - 10px);
      .green.circle {
        margin-left: 100px;
      }
    }
    // .image {
    //   height: 150px;
    //   object-fit: contain;
    //   margin: 0;
    //   border-radius: 8px; /* Ajusta el radio según necesites */
    //   padding: 1px; /* Espacio para el borde */
    //   background: linear-gradient(90deg, #e516bb 0%, #6125f0 100%);
    //   // -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    //   // -webkit-mask-composite: xor;
    //   // mask-composite: exclude;
    // }
    .circle {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        color: white;
        font-weight: bold;
      }
    }
    .green {
      background-color: rgb(0, 248, 0);
    }
    .yellow {
      background-color: rgb(216, 250, 0);
      p {
        color: black;
      }
    }
    .red {
      background-color: rgb(222, 16, 16);
    }
  }

  .content-section {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    .form-container {
      flex: 1;
      input[type='text'],
      input[type='date'],
      input[type='time'] {
        padding: 10px;
        border: none;
        background-color: transparent;
        font-family: 'Montserrat', sans-serif;
        color: white;
        &:hover,
        &:focus,
        &:focus-visible {
          color: white;
          border: none;
          outline-color: transparent;
        }
      }
      label {
        display: block;
        margin-bottom: 2px;
        font-size: 11px;
        font-weight: bold;
        font-family: 'Montserrat', sans-serif;
        color: #b0b0b0;
      }

      .input-row {
        display: flex;
        justify-content: space-between;
        gap: 20px;
        margin-bottom: 5px;
        .input-text {
          border: none;
        }
      }

      .input-row > div {
        display: flex;
        flex-direction: column;
        width: calc((100%) / 4);
        .input-text {
          border: none;
        }
      }
    }
    .small-images-container {
      float: right;
      width: 15%;
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 20px;

      .single-image-brand {
        display: flex;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        height: 25%;

        img {
          width: 100%;
          max-width: 20vh;
          object-fit: cover;
        }
      }
      .small-image {
        height: 30%;
        background-size: cover;
        background-position: center;
        display: flex;
        cursor: pointer;
        align-items: center; /* Centra el SVG verticalmente */
        justify-content: center; /* Centra el SVG horizontalmente */
      }
    }
  }
}
