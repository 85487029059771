.container-receptionist {
  height: 100%;
  width: 100%;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.2);
  font-family: 'Montserrat', sans-serif;
  color: #f4f4f4;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  align-content: center;
  padding: 0rem 0rem;
  .title-receptionist {
    margin-top: 30px;
    height: 10%;
    font-weight: 500px;
    h2 {
      font-size: 40px;
      margin-top: 5px;
    }
  }
}

.newReceptionsForm {
  .title-client {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 15%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left-component {
      position: absolute;
      left: 0;
    }
    .center-component {
      font-family: 'Montserrat', sans-serif;
      position: absolute;
      left: 50%;
      width: 300px;
      transform: translateX(-50%);
      .creating {
        font-size: 25px;
      }
      .updating {
        font-size: 20px;
      }
    }
  }
  .container-inputs {
    margin-top: -50px;
  }
}

.title-client::after {
  content: '';
  position: absolute;
  top: 65px;
  right: 0;
  width: 100%; /* Grosor del borde */
  height: 3px;
  background: linear-gradient(90deg, #e516bb 0%, #6125f0 100%);
  animation: 2s linear infinite; /* Aplica animación */
}

// .newClientForm {
//   h2 {
//     color: white;
//     margin-bottom: 10px;
//   }
// }

// .newClientForm {
//   h2 {
//     color: white;
//     margin-bottom: 10px;
//   }
// }
