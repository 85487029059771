.AccreditationsContainer {
  font-family: 'Montserrat', sans-serif;
  color: #f4f4f4;
  padding: 20px;
  margin: 0rem 0rem 5rem 0rem;
}

.title_Accreditations h4 {
  margin-bottom: 0px;
  font-size: 40px;
}

.accreditations-search-section {
  display: flex;
  gap: 1rem;
  justify-content: space-around;
  align-items: center;
  align-content: center;
  flex-wrap: nowrap;
  margin: 1rem 1rem 0rem;
  border-color: #5a1fd1;
  border-bottom-style: inset;
  padding-bottom: 10px;
  svg {
    color: white;
  }

  .dashboard-search-bar-container-dashboard {
    display: flex;
    align-items: center;
    background: rgb(80, 74, 87) !important;
    width: 20%;
    height: 30px;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 5px;
  }
}

.accreditations-search-bar-container {
  display: flex;
  align-items: center;
  background: rgba(80, 74, 87, 1) !important;
  width: 180px;
  height: 20px;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
  margin-left: auto;

  .accreditations-search-icon {
    margin-right: 8px;
  }

  .accreditations-search-input {
    color: white;
    background-color: transparent;
    border: none;
    outline: none; // Quitar el borde de color azul al hacer clic
    width: 170px; // Ocupar todo el ancho disponible

    &:focus {
      outline: none;
    }
  }

  .custom-button {
    background-color: red;
  }

  &:focus-within {
    .accreditations-search-input {
      border: none;
    }
  }
}

///////////

.accreditations-container {
  display: flex;
  margin-top: 2rem;
  flex-direction: column;
  gap: 20px;

  .header {
    border: 1px solid #261a3a;
    border-radius: 11px;
    background-color: #14113f;
  }

  .order {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    width: 100%;
    padding: 20px;
    align-items: center;
    justify-items: center;
    align-content: center;
  }

  .Payments {
    border: 1px solid #14113f;
    border-radius: 5px;
    background-color: #261a3a;
    justify-content: space-between;
    .DestineAndStatus {
      display: flex;
      gap: 4px;
      width: 100%;
      height: 100%;
      justify-content: center;
      position: relative;
      align-items: center;

      svg {
        border-radius: 100%;
        left: 0rem;
        position: absolute;
      }
    }
  }

  span {
    font-size: 14px;
    text-align: center;
  }
}

.tooltip .tooltip-content {
  visibility: hidden;
  display: inline-block;
  padding: 10px;
  background-color: #333;
  color: #fff;
  position: absolute;
  z-index: 1;
  margin-top: 20px;
}

.tooltip:hover .tooltip-content {
  visibility: visible;
}

.Container_kpis {
  display: flex;
  position: relative;
  align-items: center;
  margin: 1rem;
  justify-content: space-around;
  margin: 1rem 2rem;
  padding: 1rem 0rem 1rem;
  border-color: #5a1fd1;
  border-bottom-style: inset;
  .generalInf {
    list-style: none;
    font-size: 20px;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    div {
      display: flex;
      gap: 5px;
    }
  }
  svg {
    font-size: 2.1rem;
    margin: 0 5px;
  }
  span {
    margin: 0;
    height: 100%;
    display: inherit;
    align-items: center;
    font-weight: bold;
  }
}

.ContainerCharters {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.MonthEndReconciliations {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
  .title-conciliation {
    position: relative;
    overflow: hidden;
    width: 95%;
    height: 15%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left-component {
      position: absolute;
      left: 0;
    }
    .center-component {
      font-family: 'Montserrat', sans-serif;
      position: absolute;
      left: 50%;
      width: 58%;
      transform: translateX(-50%);
      .title {
        align-items: center;
        justify-content: center;
        font-size: 2.1rem;
        text-align: center;
      }
    }
  }

  .options {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 98%;
    height: 100%;
    margin-top: 5px;
    gap: 5px;
    .optionA {
      margin-top: 10px;
      text-align: center;
      justify-content: center;
      display: flex;
      flex-direction: column;
      width: 50%;
      height: 90%;
      .letter-a {
        display: flex;
        flex-direction: row;
        gap: 10px;
        width: 100%;
        justify-content: center;
        align-items: center;
      }
      .FileAreaSegment {
        height: 60px;
        width: 77%;
        justify-content: center;
        text-align: center;
      }

      .border-right {
        position: relative; /* Necesario para el pseudo-elemento */
        overflow: hidden; /* Para evitar desbordes */
        width: 100%;
        height: 100%;
        justify-content: center;
        .search {
          display: flex;
          flex-direction: row;
          justify-content: center;
          gap: 10px;
          width: 100%;
          margin-top: 40px;
        }
        .dates {
          margin-top: 10px;
          max-height: 170px;
          width: 73%;
          margin-left: 30px;
          overflow-y: auto;
          border-top: 2px solid #5a1fd1;
          border-bottom: 2px solid #5a1fd1;

          .date {
            text-align: center;
            justify-content: center;
            align-items: center;
            display: flex;
            gap: 15px;
            margin-top: 5px;
            margin-bottom: 5px;
            flex-wrap: wrap;
            .botones {
              width: 20px;
            }
          }
        }
      }

      .border-right::after {
        content: '';
        position: absolute;
        top: 15%;
        right: 0;
        width: 5px; /* Grosor del borde */
        height: 100%;
        background: linear-gradient(90deg, #e516bb 0%, #6125f0 100%);
        animation: 2s linear infinite; /* Aplica animación */
      }
    }

    .optionB {
      margin-top: 10px;
      // text-align: center;
      // justify-content: center;
      display: flex;
      flex-direction: column;
      width: 50%;
      height: 90%;
      .letter-b {
        display: flex;
        flex-direction: row;
        gap: 10px;
        width: 100%;
        justify-content: center;
        align-items: center;

        .init-conciliation {
          width: 100%;
          display: flex;
          justify-content: center;
        }
      }

      .letter-c {
        display: flex;
        flex-direction: row;
        gap: 10px;
        width: 100%;
        justify-content: center;
        align-items: center;
        margin-top: 50px;
      }

      .conciliation {
        display: flex;
        height: 100%;
        width: 100%;
        justify-content: center;
        flex-direction: column;
        min-height: 200px;
        .pa {
          height: 100%;
          width: 100%;
          margin-top: 100px;
        }
        .date {
          margin-top: 40px;
          width: 100%;
          display: flex;
          gap: 10px;
          align-items: center;
          justify-content: center;
          flex-wrap: wrap;

          .botones {
            width: 20px;
          }
        }
      }
    }
  }
}

/* Estilos para el modal */
.modal-loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-process {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 150px;
  background: #261a3a;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  width: 580px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  .process {
    width: 100%;
  }
  .msg {
    width: 100%;
  }
}

.progress-barr {
  width: 100%;
  height: 15px;
  background-color: #da29ba;
  border-radius: 5px;
  overflow: hidden;
  margin: 15px 0;
}

.progresss {
  height: 100%;
  background-color: #5a1fd1;
  transition: width 0.2s ease-in-out;
}
@media (max-width: 1366px) {
  .MonthEndReconciliations {
    padding: 15px; /* Reduce padding en laptops */
  }

  .MonthEndReconciliations .title {
    font-size: 1.8rem; /* Ajusta tamaño del título */
  }

  .MonthEndReconciliations .options {
    flex-direction: row; /* Mantiene distribución en laptops */
  }

  .MonthEndReconciliations .optionA,
  .MonthEndReconciliations .optionB {
    width: 48%; /* Ajusta tamaño sin perder espacio */
  }

  .MonthEndReconciliations .optionA .FileAreaSegment {
    width: 280px; /* Ajusta el ancho del área de archivos */
  }
}

// /* 🔹 Mini laptops (Ej: 768px - 1024px) */
// @media (max-width: 904px) {
//   .MonthEndReconciliations {
//     .optionA {
//       margin-right: 5px;
//       .letter-a {
//         height: 20%;
//       }
//       .border-right {
//         height: 50%;
//         .dates {
//           width: 250px; /* Reduce el ancho */
//           max-height: 150px; /* Ajusta la altura */
//           overflow-y: auto;
//           .date {
//             display: flex;
//             flex-direction: row;
//             font-size: 12px; /* Reduce tamaño de fuente */
//             gap: 8px; /* Espaciado menor */
//             padding: 5px; /* Reduce padding */
//             justify-content: center;
//             align-items: center;
//           }
//           .date h6 {
//             font-size: 10px; /* Tamaño más pequeño para la fecha */
//           }

//           .date svg,
//           .date .fa-file-csv,
//           .date .fa-file-lines {
//             font-size: 16px; /* Reduce tamaño de los íconos */
//           }
//         }
//       }
//     }
//     .optionB {
//       .letter-b {
//         .init-conciliation {
//           width: 20%;
//         }
//       }
//       .letter-c {
//         width: 10%;
//       }
//     }
//   }

//   .MonthEndReconciliations .optionA,
//   .MonthEndReconciliations .optionB {
//     width: 70%; /* Ocupar más espacio en pantallas pequeñas */
//   }

//   .dates {
//     width: 80%;
//   }

// }

.title-conciliation::after {
  content: '';
  position: absolute;
  top: 55px;
  right: 0;
  width: 100%; /* Grosor del borde */
  height: 3px;
  background: linear-gradient(90deg, #e516bb 0%, #6125f0 100%);
  animation: 2s linear infinite; /* Aplica animación */
}

/* Estilos responsivos para el modal */
@media (max-width: 1366px) {
  .modal-process {
    width: 500px; // Reduce el ancho del modal
    height: 140px;
    padding: 18px;
  }
}

@media (max-width: 1280px) {
  .modal-process {
    width: 450px;
    height: 130px;
    padding: 16px;
  }
}

@media (max-width: 1024px) {
  .modal-process {
    width: 400px;
    height: 120px;
    padding: 14px;
  }
}

@media (max-width: 768px) {
  .modal-process {
    width: 90%; // Usa un porcentaje para que se ajuste mejor en móviles
    height: 110px;
    padding: 12px;
  }

  .progress-barr {
    height: 12px; // Reduce el tamaño de la barra de progreso
  }
}

@media (max-width: 480px) {
  .modal-process {
    width: 95%;
    height: 100px;
    padding: 10px;
    border-radius: 8px;
  }

  .progress-barr {
    height: 10px;
  }
}

.ContainerCharters {
  transform: translate(-10px, 0px);
  // animation: slideDown 0.9s ease-out forwards;
  // opacity: 1;
  transition: 1.5s ease-in-out, max-height 1.5s ease-in-out;
  overflow: hidden; /* Asegura que el contenido no sobresalga */
  max-height: 400px; /* Inicialmente colapsado */
  max-width: 100%;
  position: relative;
  margin-left: 10px;
}

.chart-container {
  width: 33%;
  transition: 1.5s ease-in-out, max-height 1.5s ease-in-out;
}

@media (max-width: 600px) {
  .chart-container {
    width: 100%; /* Ajuste en pantallas pequeñas */
  }
}
