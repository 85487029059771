.loginContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  .itemsContainer {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    h2 {
      margin-bottom: 20px;
    }
    .rolesContainer {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-around;
      .rolContainer {
        cursor: pointer;
        border: 1px solid black;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        width: 20%;
        height: 100px;
        margin-top: 20px;
        margin-bottom: 20px;
        .imageContainer {
          width: 40px;
          height: 40px;
          img {
            width: 100%;
            object-fit: contain;
          }
        }
      }
      .selected {
        border-color: red;
      }
    }
    .button-login {
      display: flex;
      justify-content: center; /* Centra horizontalmente */
      align-items: center; /* Centra verticalmente (si es necesario) */
      width: 100%; /* Asegura que ocupe todo el ancho disponible */
      margin-top: 20px;
      height: 50px;
    }
  }
}
