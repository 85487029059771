.residentDetails {
  width: 100%;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  font-family: 'Montserrat', sans-serif;
  color: #f4f4f4;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  // align-content: center;
  .title {
    margin-top: 30px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    p {
      font-size: 40px;
    }
    svg {
      font-size: 3rem;
      cursor: pointer;
    }
  }
  .container-resident {
    width: 90%;
    display: flex;
  }
  .buttonAction {
    display: flex;
    flex-direction: row;
  }
  .containerButton {
    width: 90%;
    margin-left: 20px;
    gap: 10px;
    display: flex;
    flex-direction: row;
  }
}

@media screen and (max-width: 1024px) {
  .containerButton button {
    width: 150px;
  }
}

@media screen and (max-width: 768px) {
  .containerButton button {
    width: 100px;
  }
}
