$primary-color: #f600c0;
$secondary-color: #4409a4;
$sidebar-width: 280px;
$collapsed-sidebar-width: 80px;
$border-radius: 40px;

.sidebar {
  width: $sidebar-width;
  height: 100%;
  background: linear-gradient($primary-color, $secondary-color);
  border-top-right-radius: $border-radius;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  position: fixed;
  left: 0;
  margin-right: 10px;
  transition: opacity 2s ease-in-out;

  &.open {
    width: $collapsed-sidebar-width;
    transition: opacity 2s ease-in-out;
  }

  .header {
    width: 100%;
    height: 154px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .logo {
      max-width: 80%;
      max-height: 80%;
    }

    .burger {
      display: block;
      position: absolute;
      left: 29px;
      cursor: pointer;
      z-index: 1;
    }
  }

  .menuItems {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    padding-top: 20px;
    padding-left: 5%;
    padding-right: 5%;
    &.hamburguer {
      padding-left: 0%;
      padding-right: 0%;
      .menuItem {
        justify-content: center;
      }
    }
    .menuItem {
      display: flex;
      align-items: center;
      padding: 10px;
      color: white;
      cursor: pointer;
      text-decoration: none;
      width: 100%;
      justify-content: space-between;
      flex-wrap: wrap;
      transform: translate(-10px, 20px);
      animation: slideDown 0.9s ease-out forwards;
      opacity: 1;
      transition: opacity 0.9s ease-in-out;
      .icon {
        margin-right: 10px;
        width: 25px;
      }

      span {
        transition: opacity 0.5s ease-in-out;
        opacity: 1;
      }

      &.collapsed {
        span {
          opacity: 0;
        }
      }

      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 10px;
      }

      .submenu {
        display: flex;

        flex-direction: column;
        align-items: center;
        flex-grow: 1;
        width: 100%;
        overflow: hidden;
        max-height: 0;
        opacity: 0;
        margin-left: 10px;
        transition: max-height 0.9s ease-in-out, opacity 0.9s ease-in-out;
        &.open,
        &.active {
          max-height: 500px; // Ajusta según la cantidad de elementos
          opacity: 1;
        }

        .submenuItem {
          display: flex;
          align-items: center;
          padding: 8px 2px 8px 15px;
          color: white;
          cursor: pointer;
          font-size: 0.9rem;
          text-decoration: none;
          width: 100%;
          justify-content: space-between;
          align-items: center;
          animation: fadeInMenu 0.3s forwards;
          transform: translateY(-10px);
          transition: background-color 0.3s ease-in-out;
          margin-top: 5px;
          opacity: 0;
          &.active {
            background-color: rgba(255, 255, 255, 0.2);
            border-radius: 10px;
            &::before {
              content: '';
              position: absolute;
              left: 0;
              top: 0;
              height: 100%;
              background-color: white;
              width: 5px;
              border-radius: 5px;
            }
          }

          @keyframes fadeInMenu {
            to {
              opacity: 1;
              transform: translateY(0);
            }
          }
          &:hover {
            background-color: rgba(0, 0, 0, 0.1);
            border-radius: 10px;
          }
        }
      }
      &.active {
        background-color: rgba(255, 255, 255, 0.2);
        border-radius: 10px;
        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          background-color: white;
          width: 5px;
          border-radius: 5px;
        }
      }
    }
  }
  .footer {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    cursor: pointer;
    padding: 20px;
    span {
      margin-left: 20px;
    }
  }
  @keyframes slideDown {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
