.checkPointsContainer {
  height: 100%;
  width: 100%;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  font-family: 'Montserrat', sans-serif;
  color: #f4f4f4;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  align-content: center;
  padding: 0rem 1rem;
  .titleCheckPoints {
    margin-top: 30px;
    height: 10%;
    font-weight: 500px;
    margin-bottom: 0px;
    h2 {
      margin-bottom: 0px;
      font-size: 40px;
    }
  }
  .container-qr-Zip {
    width: 50%;
    display: flex;
    flex-direction: row;
    height: 45px;
    gap: 10px;
  }
}

.container-Download-Checkpoint {
  height: 50px;
}

///////////////////////
///
.residentContainer {
  height: 100%;
  width: 100%;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  font-family: 'Montserrat', sans-serif;
  color: #f4f4f4;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  align-content: center;
  padding: 0rem 1rem;
  .containerTitle {
    margin-top: 30px;
    height: 10%;
    font-weight: 500px;
    margin-bottom: 0px;
    h2 {
      font-size: 40px;
    }
  }
  .tableContainer {
    .ButtonsAdd {
      width: 58%;
      .circular-button {
        width: 10px;
      }
    }
  }
  .title {
    margin-bottom: 20px;
    p {
      font-size: 44px;
      font-weight: bold;
      margin-top: 25px;
    }
  }
  .actionsContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .enableContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      margin-left: 4px;
      font-size: large;
    }
  }

  .search-section {
    .search-bar-container {
      width: 40%;
    }

    .ButtonsAdd {
      height: 4rem;
      display: flex;
      align-items: center;
      justify-content: space-around;
      gap: 1rem;
      .custom-button.undefined {
        width: 10%;
        border-radius: 8px;
      }
      .circular-button {
        width: 5rem;
        height: 5rem;
      }
    }
  }
}

.green {
  color: rgb(8, 239, 62);
}
.red {
  color: rgb(239, 16, 8);
}

.containerDelegatesQuantity {
  width: 100%;
}

.container-template {
  width: 95%;
  .title-template {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 20%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left-component {
      position: absolute;
      left: 0;
    }
    .center-component {
      font-family: 'Montserrat', sans-serif;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .buttons-template {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
  }
  .download-template {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
}

.title-template::after {
  content: '';
  position: absolute;
  top: 50px;
  right: 0;
  width: 100%; /* Grosor del borde */
  height: 3px;
  background: linear-gradient(90deg, #e516bb 0%, #6125f0 100%);
  animation: 2s linear infinite; /* Aplica animación */
}

.title-upload {
  width: 100%;
  display: flex;
  flex-direction: column;
  .headers-modal {
    position: sticky;
    .title-questions {
      position: relative;
      overflow: hidden;
      width: 100%;
      height: 50px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      .left-component {
        position: absolute;
        left: 0;
      }
      .center-component {
        font-family: 'Montserrat', sans-serif;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 70%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .custom-upload-style {
      width: 100%;
      height: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
      .file-upload-content {
        height: 50px;
        white-space: pre-line;
        .file-upload-placeholder {
          font-size: 16px;
        }
      }
    }
  }
  .button-container {
    display: flex;
    justify-content: center;
    /* Centra horizontalmente */
  }
}

.container-templateT {
  width: 95%;
  .title-templateT {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 30%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left-component {
      position: absolute;
      left: 0;
    }
    .center-component {
      font-family: 'Montserrat', sans-serif;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .buttons-template {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
  }
  .download-template {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }
}

.title-templateT::after {
  content: '';
  position: absolute;
  top: 55px;
  right: 0;
  width: 100%; /* Grosor del borde */
  height: 3px;
  background: linear-gradient(90deg, #e516bb 0%, #6125f0 100%);
  animation: 2s linear infinite; /* Aplica animación */
}
///////////////// MODAL SETUP PLAN
///

.container-set-up-plan {
  width: 95%;
  .title-set-up-plan {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 15%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left-component {
      position: absolute;
      left: 0;
    }
    .center-component {
      font-family: 'Montserrat', sans-serif;
      position: absolute;
      left: 50%;
      width: 70%;
      display: flex;
      justify-content: center;
      align-items: center;
      align-items: center;
      display: flex;
      justify-content: center;
      transform: translateX(-50%);
      .updating {
        font-size: 23px;
      }
    }
  }
  .inputs-reserve {
    display: flex;
    flex-direction: column;
    .one-line {
      display: flex;
      flex-direction: row;
      gap: 30px;
      margin-bottom: 10px;
    }
    .two-line {
      // display: flex;
      display: grid;
      grid-template-columns: 2.5fr 2fr;
      gap: 30px;
      width: 90%;
      margin-bottom: 10px;
      .custom-number-input {
        width: 100%;
        font-weight: normal;
        height: 30px;
        align-items: center;
        justify-content: center;
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: start;
        label {
          height: 80%;
          width: 50px;
          display: flex;
          align-items: center;
          margin-left: 5px;
        }
        input {
          margin-top: 10px;
          appearance: none;
          -moz-appearance: 'textfield';
          -webkit-appearance: none;
          height: 80%;
          width: 55px;
          background: transparent;
          border: none;
          outline: none;
          align-items: center;
          color: white;
          font-size: 14px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        span {
          margin-top: 10px;

          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          width: 30px;
          font-size: 14px;
          font-weight: normal;
        }

        .controls {
          position: absolute;
          top: 0;
          right: 0;
          gap: 0px;
          margin-top: 5px;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }

        .controls button {
          background: none;
          border: none;
          font-size: 12px;
          cursor: pointer;
          color: #ffffff;
          line-height: 1;
          width: 30px;
          height: 50%;
        }

        .controls button:hover {
          color: #6f18a0;
        }
      }
    }
    .three-line {
      display: flex;
      flex-direction: row;
      gap: 30px;
    }
  }
  .button-reserve {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
  }
  .sub-container {
    height: 10%;
    .sub-title-reserve {
      margin-top: 40px;
      margin-bottom: 10px;
      position: relative;
      overflow: hidden;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .center-component {
        font-family: 'Montserrat', sans-serif;
        position: absolute;
        left: 50%;
        width: 58%;
        transform: translateX(-50%);
      }
    }
  }
}

.title-set-up-plan::after {
  content: '';
  position: absolute;
  top: 72px;
  right: 0;
  width: 100%; /* Grosor del borde */
  height: 3px;
  background: linear-gradient(90deg, #e516bb 0%, #6125f0 100%);
  animation: 2s linear infinite; /* Aplica animación */
}

@media screen and (max-width: 980px) {
  .residentContainer {
    .tableContainer {
      .ButtonsAdd {
        width: 90%; // Ajustar ancho al 100% en pantallas pequeñas

        .circular-button {
          width: 20px; // Aumentar el tamaño si es necesario
        }
      }
    }
  }
}
