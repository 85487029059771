.input-container-phone-dark {
  display: flex;
  flex-direction: column;
  cursor: pointer;

  .input-label {
    color: white;
    margin-bottom: 5px;
    margin-left: 15px;
  }

  .icon-input-container {
    background-color: transparent;
    display: flex;
    align-items: center;
    padding: 15px;
    border: #9900bb 2px solid;
    border-radius: 20px;
    max-width: 400px;
    height: 45px;

    .icon {
      width: 15px;
      height: 15px;
      margin-right: 0.5rem;
    }

    .react-tel-input {
      flex: 1;
      display: flex;
    }

    .input-element {
      flex: 1;
      font-size: 1rem;
      background-color: transparent;
      color: white;
      outline: none;
      border: none;
    }

    &:hover {
      border-color: white;
    }
  }

  .input-element::placeholder {
    color: #f4f4f4cf;
  }

  &.selectedInput {
    border-color: white;
  }
  .error-message {
    color: red;
    font-size: 12px;
    // margin-top: 4px;
  }
}
@media (max-width: 768px) {
  .icon-input-container {
    padding: 12px;
    max-width: 90%; /* Se adapta mejor en móviles */
  }
}
/* Estilo principal para el contenedor del input */
.input-container-phone-dark {
  background-color: #2a1744;
  .input-label {
    color: white;
  }

  .input-element {
    background-color: transparent;
  }
}

/* Estilos personalizados para react-phone-input-2 */

/* Estilo para el modal de selección de países */
.react-tel-input .country-list {
  background-color: #2a1744;
  border-color: #2a1744;
}

/* Estilo para cada opción de país (cuando no está seleccionada) */
.react-tel-input .country-list .country {
  background-color: #2a1744;
  color: white;
}

/* Estilo para el país seleccionado o cuando se hace hover */
.react-tel-input .country-list .country:hover,
.react-tel-input .country-list .country.highlight {
  background-color: #3b2766; /* Un tono ligeramente más claro para resaltar */
  color: white;
}

/* Estilo para el área de la bandera en el input */
.react-tel-input .flag-dropdown {
  background-color: #2a1744;
  border-color: #2a1744;
}

.react-tel-input .flag-dropdown.open .selected-flag {
  background-color: rgba(56, 78, 183, 0.3);
  border-color: #2a1744;
}

/* Estilo para el botón de selección de país */
.react-tel-input .selected-flag {
  background-color: transparent;
}

/* Estilo para la bandera cuando se selecciona */
.react-tel-input .selected-flag:hover {
  background-color: rgba(56, 78, 183, 0.3); /* Un tono ligeramente más claro */
}
