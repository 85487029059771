.clientContainer {
  height: 100%;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  font-family: 'Montserrat', sans-serif;
  color: #f4f4f4;
  display: 'flex';
  width: '100%';
  height: '100%';
  overflow: 'hidden';
  .container-sup {
    height: 570px;
    .title {
      p {
        font-size: 40px;
      }
    }
  }

  .config-cliente {
    margin-top: 20px;
    height: 138px;
    .title-config {
      position: relative;
      overflow: hidden;
      width: 100%;
      height: 59px;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      margin-left: 5px;
      align-items: center;
      display: flex;

      p {
        font-size: 40px;
        margin-right: 5px;
      }
    }
    .config-button {
      margin-left: 10px;
      margin-top: 10px;
      width: 100%;
      display: flex;
      flex-direction: row;
    }
  }
}

.title-config::after {
  content: '';
  position: absolute;
  top: 55px;
  right: 2;
  left: 0;
  width: 99%; /* Grosor del borde */
  height: 2px;
  justify-content: center;
  background: linear-gradient(90deg, #e516bb 0%, #6125f0 100%);
  animation: 2s linear infinite; /* Aplica animación */
}

.container-propietary {
  width: 95%;
  .title-propietary {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 15%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left-component {
      position: absolute;
      left: 0;
    }
    .center-component {
      font-family: 'Montserrat', sans-serif;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .buttons-propietary {
    display: grid;
    grid-template-columns: repeat(2, 1fr); // Dos columnas de igual tamaño
    gap: 20px; // Espaciado entre elementos
    margin-top: 20px;
    padding: 20px;
  }
  .button-propietary {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
  }
}

.title-propietary::after {
  content: '';
  position: absolute;
  top: 52px;
  right: 0;
  width: 100%; /* Grosor del borde */
  height: 3px;
  background: linear-gradient(90deg, #e516bb 0%, #6125f0 100%);
  animation: 2s linear infinite; /* Aplica animación */
}
