.qr-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  .title-qr {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 25%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left-component {
      position: absolute;
      left: 0;
    }
    .center-component {
      font-family: 'Montserrat', sans-serif;
      position: absolute;
      left: 50%;
      width: 62%;
      transform: translateX(-50%);
    }
  }
  .qr-code-container {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    background-color: white; /* Fondo blanco para el contenedor */
    padding: 20px; /* Ajusta según sea necesario */
    border-radius: 5px; /* Añade bordes redondeados */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Agrega una sombra */

    h2 {
      font-size: 22px;
      color: black;
      margin-bottom: 2px;
    }
    h3 {
      font-size: 18px;
      color: black;
      margin-bottom: 2px;
    }
    .qr-code-wrapper {
      margin-bottom: 5px;
      img {
        width: 100%;
        height: auto;
      }
    }
    .qr-code-texts {
      text-align: center;
    }
  }

  .qr-code-containerCheckPoint {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    background-color: white; /* Fondo blanco para el contenedor */
    padding: 20px; /* Ajusta según sea necesario */
    border-radius: 5px; /* Añade bordes redondeados */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Agrega una sombra */

    h2 {
      font-size: 22px;
      color: black;
      margin-bottom: 2px;
    }
    h3 {
      font-size: 18px;
      color: black;
      margin-bottom: 2px;
    }
    .qr-code-wrapper {
      margin-bottom: 5px;
      img {
        width: 100%;
        height: auto;
      }
    }
    .qr-code-texts {
      text-align: center;
    }

    .Mantenimiento {
      color: #008000;
    }
    .Seguridad {
      color: #002d80;
    }
  }

  .button-container {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    width: 67%;
  }
}

.title-qr::after {
  content: '';
  position: absolute;
  top: 80px;
  right: 0;
  width: 100%; /* Grosor del borde */
  height: 3px;
  background: linear-gradient(90deg, #e516bb 0%, #6125f0 100%);
  animation: 2s linear infinite; /* Aplica animación */
}
