.residentContainer {
  height: 100%;
  width: 100%;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  font-family: 'Montserrat', sans-serif;
  color: #f4f4f4;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  align-content: center;
  padding: 0rem 1rem;
  .containerTitle {
    margin-top: 30px;
    height: 10%;
    font-weight: 500px;
    margin-bottom: 0px;
    h2 {
      font-size: 40px;
    }
  }
  .tableContainer {
    .ButtonsAdd {
      width: 58%;
      .circular-button {
        width: 10px;
      }
    }
  }
  .title {
    margin-bottom: 20px;
    p {
      font-size: 44px;
      font-weight: bold;
      margin-top: 25px;
    }
  }
  .actionsContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .enableContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      margin-left: 4px;
      font-size: large;
    }
  }

  .search-section {
    .search-bar-container {
      width: 40%;
    }

    .ButtonsAdd {
      height: 4rem;
      display: flex;
      align-items: center;
      justify-content: space-around;
      gap: 1rem;
      .custom-button.undefined {
        width: 10%;
        border-radius: 8px;
      }
      .circular-button {
        width: 5rem;
        height: 5rem;
      }
    }
  }
}

.green {
  color: rgb(8, 239, 62);
}
.red {
  color: rgb(239, 16, 8);
}

.containerDelegatesQuantity {
  width: 100%;
}

.container-template {
  width: 95%;
  .title-template {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 20%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left-component {
      position: absolute;
      left: 0;
    }
    .center-component {
      font-family: 'Montserrat', sans-serif;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .buttons-template {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
  }
  .download-template {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
}

.title-template::after {
  content: '';
  position: absolute;
  top: 50px;
  right: 0;
  width: 100%; /* Grosor del borde */
  height: 3px;
  background: linear-gradient(90deg, #e516bb 0%, #6125f0 100%);
  animation: 2s linear infinite; /* Aplica animación */
}

.title-upload {
  width: 100%;
  display: flex;
  flex-direction: column;
  .headers-modal {
    position: sticky;
    .title-questions {
      position: relative;
      overflow: hidden;
      width: 100%;
      height: 50px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      .left-component {
        position: absolute;
        left: 0;
      }
      .center-component {
        font-family: 'Montserrat', sans-serif;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 50%;
        .buttons-template {
          position: relative;
          overflow: hidden;
          width: 100%;
          height: 20%;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
    }

    .custom-upload-style {
      width: 100%;
      // margin-left: 46px;
      height: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .button-container {
    display: flex;
    justify-content: center;
    /* Centra horizontalmente */
  }
}

@media screen and (max-width: 980px) {
  .residentContainer {
    .tableContainer {
      .ButtonsAdd {
        width: 90%; // Ajustar ancho al 100% en pantallas pequeñas

        .circular-button {
          width: 20px; // Aumentar el tamaño si es necesario
        }
      }
    }
  }
}
