.checkPointsContainer {
  height: 100%;
  width: 100%;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  font-family: 'Montserrat', sans-serif;
  color: #f4f4f4;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  align-content: center;
  padding: 0rem 0rem;
  .titleCheckPoints {
    margin-top: 20px;
    height: 30%;
    font-weight: 500px;
    h2 {
      font-size: 40px;
      margin-top: 5px;
    }
  }
}
