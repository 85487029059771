.SegmentsContainer {
  .pagination {
    display: flex;
    align-items: center;
    margin-left: 20px;

    .arrow {
      color: white;
    }

    .page-number {
      margin: 0 10px;
      color: white;
    }
  }
  .MuiButtonBase-root {
    width: 50px;
  }
}

.Container-reason {
  justify-content: space-between;
  display: flex;
  margin-top: 0.5rem;
  flex-direction: column;
  margin-left: 40px;
  margin-right: 20px;
  .title-reason-and-rules {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: space-between;
    .info-header {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      width: 85%;
      color: #5a1fd1;
      .name {
        margin-left: 20px;
      }
    }
  }
  .name-result {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: space-between;
    .name-result-info {
      grid-template-columns: repeat(3, 1fr);
      display: grid;
      width: 85%;
    }
    .icons-grid {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      color: white;
      gap: 5px;
      align-items: center;
    }
  }
}

.Container_reason_and_rules {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px;

  .title {
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
  }
  .ant-input-affix-wrapper {
    padding: 15px;
  }
  .ant-input-prefix {
    font-weight: normal;
  }

  .sub-container-reason-and-rules {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    .custom-number-input {
      margin: 0;
      padding: 5px;
      position: relative;
      height: 50px;
      border-radius: 20px;
      display: grid;
      align-items: center;
      justify-content: center;
      grid-template-columns: 2fr 1fr 1fr;
      span {
        width: 20%;
      }
      label {
        justify-content: center;
        margin-bottom: 9px;
        width: 80%;
        color: white;
      }
      input {
        appearance: none;
        -moz-appearance: 'textfield';
        -webkit-appearance: none;
        width: 100%;
        background-color: transparent;
        border: none;
        outline: none;
        text-align: center;
        color: white;
        font-size: 16px;
      }

      .controls {
        position: absolute;
        top: 0;
        right: 0;
        gap: 2px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 11px;
      }

      .controls button {
        background: none;
        border: none;
        font-size: 12px;
        cursor: pointer;
        color: #ffffff;
        line-height: 1;
        width: 30px;
        height: 50%;
      }

      .controls button:hover {
        color: #6f18a0;
      }
    }
  }

  .button {
    margin-top: 20px;
  }
}

@media (max-width: 768px) {
  .title-reason-and-rules,
  .name-result {
    flex-direction: column;
    align-items: center;
  }

  .info-header,
  .name-result-info {
    grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
    width: 100%;
  }

  .icons-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
