.Container-message-center {
  // height: 100%;
  width: 100%;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  font-family: 'Montserrat', sans-serif;
  color: #f4f4f4;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  align-content: center;
  padding: 0rem 0rem;
  .containerTitle {
    margin-top: 30px;
    height: 20%;
    font-weight: 500px;
    h2 {
      font-size: 40px;
    }
  }
  .Message_center-search-section {
    .dashboard-search-bar-container-dashboard {
      width: 100%;
    }
  }
  .circulare-button {
    width: 60px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: none;
    cursor: pointer;
    transition: transform 0.2s ease, background 0.2s ease;
    border-radius: 50%;
    .plus-icon {
      font-size: 55px;
      color: #e0e0e0; /* Color inicial */
      transition: color 0.2s ease;
    }
  }
  .circulare-button:hover {
    transform: scale(1.1); /* Efecto de agrandado */
    background: rgb(159, 159, 159, 0.1); /* Suave cambio de fondo */
  }
}

.title_Accreditations h4 {
  margin-bottom: 0px;
  font-size: 40px;
}

.Message_center-search-section {
  display: flex;
  gap: 1rem;
  justify-content: space-around;
  align-items: center;
  align-content: center;
  flex-wrap: nowrap;
  margin: 2rem 1rem 0rem;
  border-color: #5a1fd1;
  border-bottom-style: inset;
  padding-bottom: 10px;

  .circular-button {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: linear-gradient(to right, #f600c0 0%, #4409a4 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    cursor: pointer;
  }

  .circular-button:active,
  .circular-button:focus {
    outline: none;
  }

  .circular-button .plus-icon {
    font-size: 65px;
    color: white;
  }
}

.Message_center-search-bar-container {
  display: flex;
  align-items: center;
  background: rgba(80, 74, 87, 1) !important;
  width: 180px;
  height: 20px;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
  margin-left: auto;

  .Message_center-search-icon {
    margin-right: 8px;
  }

  .Message_center-search-input {
    color: white;
    background-color: transparent;
    border: none;
    outline: none; // Quitar el borde de color azul al hacer clic
    width: 170px; // Ocupar todo el ancho disponible

    &:focus {
      outline: none;
    }
  }

  &:focus-within {
    .Message_center-search-input {
      border: none;
    }
  }
}

///////////

.Message_center-container {
  display: flex;
  margin-top: 2rem;
  flex-direction: column;
  gap: 20px;

  .header {
    border: 1px solid #261a3a;
    border-radius: 11px;
    background-color: #14113f;
  }

  .order {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    width: 100%;
    padding: 20px;
    align-items: center;
    justify-items: center;
    align-content: center;
  }

  .Payments {
    border: 1px solid #14113f;
    border-radius: 5px;
    background-color: #261a3a;
    justify-content: space-between;
  }

  span {
    font-size: 14px;
    text-align: center;
  }
}

.tooltip .tooltip-content {
  visibility: hidden;
  display: inline-block;
  padding: 10px;
  background-color: #333;
  color: #fff;
  position: absolute;
  z-index: 1;
  margin-top: 20px;
}

.tooltip:hover .tooltip-content {
  visibility: visible;
}

.ModalCreate-container {
  width: 90%;
  height: 90%;
  margin: auto;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .ModalCreate-Form {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    .fullHeight {
      flex: 1;
    }
    .fomrText {
      width: 48%;
      height: 100%;
      display: flex;
      gap: 1rem;
      flex-direction: column;

      .FileArea {
        width: 100%;
        cursor: pointer;
        .text {
          align-content: center;
          text-align: center;
        }
      }
    }
    .selects {
      width: 48%;
      display: flex;
      gap: 3rem;
      flex-direction: column;
      justify-content: center;
      position: relative;
      span {
        font-weight: 300;
      }

      .multiselect {
        height: auto;
        max-height: 150px;
        width: 100%;
        top: 300px;
        overflow: hidden; /* Evita el desbordamiento hasta que se expanda */
        transition: max-height 0.3s ease-in-out;
        .ant-select-selector {
          max-height: 100px;
          overflow: auto;
        }
        .ant-select-selection-item {
          overflow: hidden;
          text-overflow: ellipsis; /* Agrega puntos suspensivos (...) */
          white-space: nowrap; /* Impide el salto de línea */
        }
      }

      .selectType {
        position: absolute;
        width: 100%;
        top: 0px;
      }
      .checkboxes {
        display: flex;
        position: absolute;
        width: 100%;
        top: 70px;
        cursor: pointer;
        display: inline-block;

        .checkbox .ant-checkbox-inner {
          position: relative;
          background-color: transparent;
          border: 1px solid #5a1fd1;
        }
      }

      .selectresonAndRules {
        position: absolute;
        width: 100%;
        top: 140px;
      }
    }
  }

  .bottomAndInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    bottom: 0;
    position: absolute;
    width: 100%;
    .info {
      p {
        width: 100%;
        color: red;
      }
    }
    .button {
      width: 95%;
      height: 4rem;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      .text {
        align-self: center;
        font-size: 18px;
        font-weight: bold;
      }
    }
  }
}

@media (max-width: 900px) {
  .Container-message-center {
    .containerTitle {
      h2 {
        font-size: 30px;
      }
    }
  }
}
