.container-certifier {
  width: 95%;
  .title-certifier {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 15%;
    display: flex;
    justify-content: space-between;
    // align-items: center;
    .left-component {
      margin-top: 22px;

      position: absolute;
      left: 0;
    }
    .center-component {
      font-family: 'Montserrat', sans-serif;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      h3 {
        margin-top: 15px;
      }
    }
  }
  .buttons-certifier {
    display: grid;
    grid-template-columns: repeat(3, 1fr); // Dos columnas de igual tamaño
    gap: 25px; // Espaciado entre elementos
    margin-top: 0px;
  }
  .button-certifier {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .input-dark-large {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
  }

  .largue3 {
    grid-column: span 3;
  }

  .largue2 {
    grid-column: span 2;
  }
}
.title-certifier::after {
  content: '';
  position: absolute;
  top: 58px;
  right: 0;
  width: 100%; /* Grosor del borde */
  height: 3px;
  background: linear-gradient(90deg, #e516bb 0%, #6125f0 100%);
  animation: 2s linear infinite; /* Aplica animación */
}
