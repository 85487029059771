.custom-number-hour {
  position: relative;
  width: 100%;
  .input-hour {
    background-color: transparent;
    border: none;
    outline: none;
    color: white;
    height: 100%;
    font-size: 14px;
    width: 100%;
  }
}

.input-hour::placeholder {
  color: rgba(255, 255, 255, 0.827);
}
