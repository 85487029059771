.newCheckPointsLocationForm {
  .title-access {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 15%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left-component {
      position: absolute;
      left: 0;
    }
    .center-component {
      font-family: 'Montserrat', sans-serif;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      .updating {
        font-size: 23px;
      }
    }
  }
}

.title-access::after {
  content: '';
  position: absolute;
  top: 52px;
  right: 0;
  width: 100%; /* Grosor del borde */
  height: 3px;
  background: linear-gradient(90deg, #e516bb 0%, #6125f0 100%);
  animation: 2s linear infinite; /* Aplica animación */
}
