.popup-overlay-qr-limit {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;

  .popup {
    padding: 20px 20px;
    border-radius: 5px;
    text-align: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50%;
    gap: 12px;
    h3 {
      font-size: 25px;
      font-weight: bold;
    }
    .itemsContainer {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 10px;
      align-items: center;
      justify-content: center;
      margin-bottom: 15px;

      .label.label {
        margin: 0px;
        margin-bottom: 5px;
      }
      .input-container-large {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        .input-label {
          margin-left: 0px;
        }
        .icon-input-container {
          width: 100px;
          display: flex;
          justify-content: center;
          .input-element {
            width: 30px;
          }
        }
      }
    }
    .itemsContainerButton {
      display: flex;
      justify-content: space-between;
      width: 90%;
      .accept-button {
        background-color: rgb(38, 26, 58);
        color: white;
        height: 40px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: relative;
        outline: none;
        font-size: 16px;
        border-radius: 12px;
        padding: 2px;
        border: none;
      }
      .accept-button:hover {
        filter: brightness(1.2); /* Aumenta el brillo */
        transform: scale(1); /* Ligeramente más grande */
        background-color: rgb(124, 16, 132);
      }

      .accept-button:active {
        transform: scale(0.98); /* Efecto de clic */
      }
    }
  }
}
