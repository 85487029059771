.Container_penalty {
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  p {
    color: white;
  }

  .button {
    cursor: pointer;
  }
  .politic {
    width: 780px;
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-left: 10px;
    margin-top: 10px;
    .bottons {
      width: 100%;
      display: flex;
      flex-direction: row;
      gap: 220px;
      .boton-active {
        display: flex;
        flex-direction: row;
        gap: 12px;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .section-D {
    width: 880px;
    display: flex;
    flex-direction: row;
    gap: 25px;
    align-items: center;
    height: 100%;
    margin-top: 10px;
  }
  .title {
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h4 {
      margin: 0;
      font-size: 28px;
      font-weight: bold;
    }
    p {
      font-size: 20px;
      font-weight: 300;
    }
  }
  .Config_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    .Options {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1rem;
      .custom-number-input {
        position: relative;
        width: 100%;

        input {
          appearance: none;
          -moz-appearance: 'textfield';
          -webkit-appearance: none;
          width: 50%;
          background-color: transparent;
          border: none;
          outline: none;
          text-align: end;
          color: white;
        }

        .controls {
          position: absolute;
          top: 0;
          right: 0;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }

        .controls button {
          background: none;
          border: none;
          font-size: 10px;
          cursor: pointer;
          color: #ffffff;
          line-height: 1;
          width: 30px;
          height: 50%;
        }

        .controls button:hover {
          color: #c567fc;
        }
      }

      &.section1 {
        display: flex;
        gap: 1rem;
        margin: 0 1rem;
      }
    }
  }
  .example {
    .Example_div {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      margin: 1rem;
      p {
        width: 50%;
      }
    }
  }
}
