/* style.scss */
.custom-select-container {
  display: flex;
  flex-direction: column;
  margin-top: 10px;

  .MuiFormControl-root {
    width: 400px;
    height: 60px;
  }

  .MuiAutocomplete-root {
    &.Mui-focused {
      .MuiAutocomplete-inputRoot {
        border-color: #484747;
      }
    }

    .MuiAutocomplete-inputRoot {
      border: #9900bb 2px solid;

      border-radius: 20px;

      display: flex;
      align-items: center;
      background-color: transparent;
      // background-color: #263371 !important;

      .MuiAutocomplete-input {
        color: white;
      }

      .MuiAutocomplete-popupIndicator {
        color: white;
      }
      .MuiAutocomplete-endAdornmen {
        // background-color: #263371;
        background-color: #261a3a;
      }
    }

    .MuiAutocomplete-clearIndicator {
      color: white;
    }

    .MuiAutocomplete-option {
      color: white;

      &.Mui-focused {
        // background-color: rgba(56, 78, 183, 0.5);
        background-color: #261a3a;
      }
    }
  }

  .MuiAutocomplete-endAdornment {
    // background-color: #263371 !important;
    background-color: #261a3a;
  }
}
