.container-propietary {
  width: 95%;
  .title-propietary {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 15%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left-component {
      position: absolute;
      left: 0;
    }
    .center-component {
      font-family: 'Montserrat', sans-serif;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .buttons-propietary {
    display: grid;
    grid-template-columns: repeat(2, 1fr); // Dos columnas de igual tamaño
    gap: 20px; // Espaciado entre elementos
    margin-top: 20px;
    padding: 20px;
  }
  .button-propietary {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
  }
}

.title-propietary::after {
  content: '';
  position: absolute;
  top: 52px;
  right: 0;
  width: 100%; /* Grosor del borde */
  height: 3px;
  background: linear-gradient(90deg, #e516bb 0%, #6125f0 100%);
  animation: 2s linear infinite; /* Aplica animación */
}
