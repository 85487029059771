.container-res {
  // height: 100%;
  width: 100%;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  font-family: 'Montserrat', sans-serif;
  color: #f4f4f4;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  align-content: center;
  padding: 0rem 0rem;
  .container-reserve {
    // height: 20%;
    // font-weight: 500px;
    .TitlePageContainer {
      margin-top: 35px;
    }
    .container-headers-reserve {
      margin-top: -20px;
      height: 160px;
      display: grid;
      grid-template-columns: 1fr 1fr 1.5fr;
      align-items: center;
      position: relative; /* Necesario para el pseudo-elemento */
      overflow: hidden; /* Para evitar desbordes */
      .container-headers-button {
        padding-right: 5px;
        height: 160px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative; /* Necesario para el pseudo-elemento */
        overflow: hidden; /* Para evitar desbordes */
        .search-section {
          margin-top: 15px;
          display: flex;
          width: 98%;
          height: 90%;
          align-items: start;
          justify-content: center;
          .ant-select-arrow {
            color: white;
            font-size: 12px;
          }
        }
      }
      .reserve-content-data {
        width: 100%;
        height: 160px;
        display: flex;
        position: relative; /* Necesario para el pseudo-elemento */
        overflow: hidden; /* Para evitar desbordes */
        align-items: center;
        justify-content: center;
        gap: 10px;
        .data-result {
          display: flex;
          flex-direction: row;
          width: 100%;
          height: 90%;

          .data {
            p {
              height: 23px;
              margin-left: 10px;
            }
          }
          .data-resultt {
            p {
              height: 23px;
              margin-left: 10px;
            }
          }
        }
        .data-null {
          width: 75%;
          align-items: center;
          justify-content: center;
          display: flex;
          text-align: center;

          color: white;
          text-transform: uppercase;
        }
      }
      .reserve-content-info {
        height: 160px;
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 2px;
        .icono {
          height: 160px;
          width: 30%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .info {
          height: 160px;
          width: 50%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 5px;
          .ubication {
            font-weight: bold;
            margin-left: 45px;
          }
          p {
            margin-left: 45px;
          }
          .dates {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            text-align: center;
            p {
              align-items: center;
              justify-content: center;
              display: flex;
            }
          }
        }
        .calend {
          height: 115px;
          width: 8%;
          display: flex;
          align-items: end;
          justify-content: end;
          cursor: pointer;
          position: relative;
          margin-left: 25px;
        }
      }
    }
    .container-columns {
      display: grid;
      grid-template-columns: 1fr 1fr 1.5fr;
      margin-bottom: 30px;
      .container-left {
        position: relative; /* Necesario para el pseudo-elemento */
        overflow: hidden; /* Para evitar desbordes */
        place-items: center;
        display: flex;
        flex-direction: column;
        .tittle-segment {
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 100%;
          justify-content: center;
          margin-top: 10px;
          gap: 10px;

          h5 {
            font-size: 30px;
            margin-top: 10px;
          }
        }
        .segments {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 15px;
          .segment-all {
            position: relative;
            border: 3px solid white;
            border-radius: 10px;
            padding: 15px 10px;
            width: 140px;
            margin-top: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            transition: border 0.3s ease-in-out, background-color 0.3s;
          }
          .segment-item {
            width: 140px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            border: 3px solid white;
            border-radius: 10px;
            padding: 5px 5px;
            cursor: pointer;
            transition: border 0.3s ease-in-out, background-color 0.3s;
          }
          .segment-item.selected,
          .segment-all.selected {
            border-color: transparent;
          }

          .segment-item.selected::before,
          .segment-all.selected::before {
            content: '';
            position: absolute;
            top: -3px;
            left: -3px;
            right: -3px;
            bottom: -3px;
            border-radius: 10px;
            padding: 3px;
            background: linear-gradient(90deg, #e516bb 0%, #6125f0 100%);
            -webkit-mask: linear-gradient(white, white) content-box,
              linear-gradient(white, white);
            -webkit-mask-composite: destination-out;
            mask-composite: exclude;
          }
        }
        .border-segment {
          cursor: pointer;
          margin-top: 30px;
          position: relative;
          border: 3px solid white;
          border-radius: 10px;
          padding: 5px;
          transition: border 0.3s ease-in-out;
        }

        .border-segment.selected {
          border-color: transparent;
        }

        .border-segment.selected::before {
          content: '';
          position: absolute;
          top: -3px;
          left: -3px;
          right: -3px;
          bottom: -3px;
          border-radius: 10px;
          padding: 3px;
          background: linear-gradient(90deg, #e516bb 0%, #6125f0 100%);
          -webkit-mask: linear-gradient(white, white) content-box,
            linear-gradient(white, white);
          -webkit-mask-composite: destination-out;
          mask-composite: exclude;
        }
      }
      .container-center {
        position: relative; /* Necesario para el pseudo-elemento */
        overflow: hidden; /* Para evitar desbordes */
        display: flex;
        flex-direction: column;
        .tittle-location {
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 100%;
          justify-content: center;
          margin-top: 10px;
          gap: 10px;

          h5 {
            font-size: 30px;
            text-align: center;
            margin-top: 10px;
          }
        }
        .locations {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 15px;
          .location-all {
            position: relative;
            border: 3px solid white;
            border-radius: 10px;
            padding: 15px 10px;
            width: 200px;
            margin-top: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            transition: border 0.3s ease-in-out, background-color 0.3s;
          }
          .location-item {
            width: 200px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            border: 3px solid white;
            border-radius: 10px;
            padding: 15px 10px;
            cursor: pointer;
            transition: border 0.3s ease-in-out, background-color 0.3s;
          }

          .location-item.selected,
          .location-all.selected {
            border-color: transparent;
          }

          .location-item.selected::before,
          .location-all.selected::before {
            content: '';
            position: absolute;
            top: -3px;
            left: -3px;
            right: -3px;
            bottom: -3px;
            border-radius: 10px;
            padding: 3px;
            background: linear-gradient(90deg, #e516bb 0%, #6125f0 100%);
            -webkit-mask: linear-gradient(white, white) content-box,
              linear-gradient(white, white);
            -webkit-mask-composite: destination-out;
            mask-composite: exclude;
          }
        }
      }
      .container-right {
        display: flex;
        flex-direction: column;
        .tittle-calendar {
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 100%;
          justify-content: center;
          margin-top: 10px;
          gap: 10px;

          h5 {
            font-size: 30px;
            text-align: center;
            margin-top: 10px;
          }
        }
        .container-calendar {
          margin-top: 10px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          height: 75px;
          margin-left: 12px;
          width: 95%;
        }

        .data-add {
          display: flex;
          justify-content: end;
        }
        .number-reservas {
          display: flex;
          position: relative;
          margin-top: 10px;
          .number {
            margin-left: 20px;
            display: flex;
            flex-direction: column;
            gap: 18px;
            width: 50px;

            p {
              font-size: 25px;
              font-weight: bold;
            }
          }
          .reservation {
            position: relative;
            flex-grow: 1;
            padding-left: 10px;
            .summary {
              position: absolute;
              // left: 50%;
              // transform: translateX(-50%);
            }

            .location-details {
              display: grid;
              grid-template-columns: 9fr 1fr;
              position: absolute;
              left: 10px;
              width: calc(100% - 20px);
              border-radius: 8px;
              padding: 0px 10px;
              margin-bottom: 15px;
              box-sizing: border-box;
              overflow: hidden; /* Evita que el contenido se desborde */
              max-height: 100%;
              .detail {
                justify-content: center;
                display: flex;
                flex-direction: column;
                gap: 2px;
                height: 100%;
                overflow: auto; /* Asegura que los elementos internos tampoco se desborden */
                max-height: inherit; /* Hereda el máximo tamaño del contenedor padre */
                padding-right: 5px;
              }

              .icon {
                display: flex;
                flex-direction: column; /* Acomoda los elementos en columna */
                justify-content: space-between; /* Separa los elementos al máximo */
                height: 100%;
                align-items: center;
                align-items: center;
                width: 25px;
                margin-left: 18px;
              }
            }
          }
        }
      }
    }
  }
}

.container-headers-button::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 5px; /* Grosor del borde */
  height: 90%;
  // background: linear-gradient(90deg, #e516bb 0%, #6125f0 100%);
  background: #523b71;
  animation: 2s linear infinite; /* Aplica animación */
}

.reserve-content-data::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 5px; /* Grosor del borde */
  height: 90%;
  background: #523b71;

  animation: 2s linear infinite; /* Aplica animación */
}

.container-headers-reserve::after {
  content: '';
  position: absolute;
  top: 155px;
  margin-left: 15px;
  width: 98%; /* Grosor del borde */
  height: 5px;
  background: #523b71;

  animation: 2s linear infinite; /* Aplica animación */
}

.container-left::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 5px; /* Grosor del borde */
  height: 100%;
  background: #523b71;
  animation: 2s linear infinite; /* Aplica animación */
}

.container-center::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 5px; /* Grosor del borde */
  height: 100%;
  background: #523b71;

  animation: 2s linear infinite; /* Aplica animación */
}

//////////////////////////////////////////////////////////////////////////////////////////////////
/// MODAL
//////////////////////////////////////////////////////////////////////////////////////////////////

.container-reserve {
  width: 95%;
  height: 100%;
  .title-reserve {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: space-between;
    .left-component {
      position: absolute;
      left: 0;
    }
    .center-component {
      font-family: 'Montserrat', sans-serif;
      position: absolute;
      left: 50%;
      width: 58%;
      align-items: center;
      display: flex;
      justify-content: center;
      transform: translateX(-50%);
      .updating {
        font-size: 23px;
      }
    }
  }
  .inputs-reserve {
    display: flex;
    flex-direction: column;
    .one-line {
      display: flex;
      flex-direction: row;
      gap: 30px;
      margin-bottom: 10px;
      align-items: end;
    }
    .two-line {
      // display: flex;
      display: grid;
      grid-template-columns: 2.5fr 2fr;
      gap: 30px;
      width: 90%;
      margin-bottom: 10px;
    }
    .three-line {
      display: flex;
      flex-direction: row;
      gap: 30px;
      .ant-select-selection-placeholder {
        font-weight: normal;
        color: rgb(220, 217, 217);
      }
    }
  }
  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
  }
  .button-reserve {
    display: flex;
    align-items: center;
    margin-top: 15px;
    width: 100%;
    justify-content: flex-start;
    margin-bottom: 20px;
    margin-left: 5px;
  }
  .sub-container {
    height: 10%;
    .sub-title-reserve {
      margin-top: 6px;
      margin-bottom: 10px;
      position: relative;
      overflow: hidden;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .center-component {
        font-family: 'Montserrat', sans-serif;
        position: absolute;
        left: 50%;
        width: 80%;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: translateX(-50%);
      }
    }
  }
}

.title-reserve::after {
  content: '';
  position: absolute;
  top: 40px;
  right: 0;
  width: 100%; /* Grosor del borde */
  height: 3px;
  background: linear-gradient(90deg, #e516bb 0%, #6125f0 100%);
  animation: 2s linear infinite; /* Aplica animación */
}

.sub-title-reserve::after {
  content: '';
  position: absolute;
  top: 57px;
  right: 0;
  width: 100%; /* Grosor del borde */
  height: 3px;
  background: linear-gradient(90deg, #e516bb 0%, #6125f0 100%);
  animation: 2s linear infinite; /* Aplica animación */
}

.container-reserve-confirm {
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    width: 90%;
    margin-top: 20px;
  }
}
