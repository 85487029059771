.custom-button {
  background: linear-gradient(to left, #f600c0 0%, #4409a4 100%);
  color: white;
  border: none;
  border-radius: 8px;
  padding: 10px 20px;
  font-weight: bold;
  line-height: 1.5;
  font-size: 1rem;
  margin: 0 auto;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.3s ease, transform 0.2s ease;
  width: 180px;
  height: 40px;

  &:hover {
    background: #7c1084;
    transform: scale(1.05);
  }

  &.cancel {
    background: none;
    border: 1px solid white;

    &:hover {
      background: rgba(255, 255, 255, 0.1);
    }
  }

  &.disable {
    background: linear-gradient(to left, #382d2d 0%, #2a1d47 100%);
    border: 0.5px solid rgb(0, 0, 0);
    cursor: not-allowed;
    opacity: 0.5;
  }

  &.selected {
    border: 2px solid white;
  }

  .button-icon {
    margin-right: 8px;
  }
}
