.newResidentForm {
  .title-resident {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 15%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left-component {
      position: absolute;
      left: 0;
    }
    .center-component {
      font-family: 'Montserrat', sans-serif;
      position: absolute;
      left: 50%;
      width: 300px;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: translateX(-50%);
      .creating {
        font-size: 25px;
      }
      .updating {
        font-size: 20px;
      }
    }
  }
}

.title-resident::after {
  content: '';
  position: absolute;
  top: 65px;
  right: 0;
  width: 100%; /* Grosor del borde */
  height: 3px;
  background: linear-gradient(90deg, #e516bb 0%, #6125f0 100%);
  animation: 2s linear infinite; /* Aplica animación */
}

// .formContainer {
//   display: flex;
//   flex-direction: column;
//   gap: 15px;
//   width: 100%;
// }

// .elementsContainer {
//   display: flex;
//   flex-direction: column;
//   gap: 10px;
// }

.formElement {
  display: flex;
  flex-direction: column;
  width: 100%;
}

// input,
// select {
//   width: 100%;  // Hace que todos los inputs ocupen el mismo ancho
//   padding: 10px;
//   font-size: 16px;
//   border: 1px solid #ccc;
//   border-radius: 5px;
//   box-sizing: border-box;
// }

// input:focus,
// select:focus {
//   border-color: #007bff;
//   outline: none;
// }

// .button {
//   width: 100%;
//   padding: 10px;
//   font-size: 16px;
//   background-color: #007bff;
//   color: white;
//   border: none;
//   border-radius: 5px;
//   cursor: pointer;
// }

// .button:hover {
//   background-color: #0056b3;
// }
