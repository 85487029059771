.button-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

@media screen and (max-width: 768px) {
  .button-section {
    width: 450px;
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      justify-items: center;
    }
  }
}
